import React, { Suspense, lazy, useContext, useEffect } from 'react';
import { PageProps, Link, graphql } from 'gatsby';
import SEO from '@/components/seo';
import hoverCircle from '@/components/Interaction/hoverCircle';
import scrollAnim from '@/components/Interaction/scrollAnim';
import works from '@/components/Interaction/works';
import mv from '@/components/Interaction/mv';
import service from '@/components/Interaction/service';

import imgSprite from '@/assets/images/common/symbol-defs.svg';
import { Context } from '@/components/Layout';

import ServiceFeature from '@/components/Service/ServiceFeature';

const NewsFeature = lazy(() => import(`@/components/News/NewsFeature`));
const CasesFeature = lazy(() => import(`@/components/Cases/CasesFeature`));
const BlogFeature = lazy(() => import(`@/components/Blog/BlogFeature`));
const OuterDisclaimer = lazy(() => import(`@/components/OuterDisclaimer`));
const AboutAnim = lazy(() => import('@/components/Top/AboutAnim'));

const Index: React.FC<PageProps<GatsbyTypes.IndexQuery>> = ({ data }) => {
  const loading = useContext(Context);
  useEffect(() => {
    hoverCircle();
    scrollAnim();
    mv();
    service();
  }, []);

  return (
    !loading && (
      <>
        <SEO title="" />
        <section className="mv">
          <div className="mv__container" />
          <div className="mv-lead">
            <h1 className="mv-lead__title">
              <svg
                role="img"
                aria-label="みたい、ミライを"
                className="mv-lead-svg"
              >
                <use href={`${imgSprite}#img_copy`} />
              </svg>
            </h1>
            <p className="mv-lead__txt">
              私たちはデジタルの力をたずさえ、
              <br />
              お客さまとともに紡いだ「ミライ」の実現に向け、
              <br />
              絶え間ない挑戦を続けます。
            </p>
          </div>
        </section>
        <section className="content" id="news">
          <div className="content-inner">
            <div className="news-grid content-inner-padding">
              <div className="news-grid__grid-item">
                <h2 className="content-title">News</h2>
                <div className="circle-link">
                  <Link
                    to="/news/"
                    className="circle-link__txt js-hover-circle"
                    data-circle-type="stick"
                    data-circle-inner="arrow-right"
                  >
                    View More
                  </Link>
                </div>
              </div>
              <Suspense fallback={null}>
                <NewsFeature topLimitNews={data.microcmsEnv.topLimitNews} />
              </Suspense>
            </div>
          </div>
        </section>
        <section className="content" id="service">
          <div className="content-inner">
            <div className="ttl__block content-inner-padding">
              <div className="content-grid content-grid--tab">
                <h2 className="content-title content-grid__grid-item">Service</h2>
                <div className="content-lead content-grid__grid-item">
                  <h3 className="content-lead__subTitle">
                    各分野のプロフェッショナルが、
                    <br className="d-pc-inline" />
                    お客さまのビジネスをサポート
                  </h3>
                  <p className="content-lead__txt">
                    異なる専門性を持った4つの事業領域で、お客さまのビジネス課題に寄り添います。
                  </p>
                  <div className="circle-link">
                    <Link
                      to="/service/"
                      className="circle-link__txt js-hover-circle"
                      data-circle-type="stick"
                      data-circle-inner="arrow-right"
                    >
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-content">
              <ServiceFeature />
            </div>
          </div>
        </section>
        <section className="content" id="works" onLoad={works}>
          <div className="content-inner content-grid">
            <div className="content-grid__grid-item content-inner-padding">
              <h2 className="content-title">Works</h2>
              <div className="content-lead">
                <p className="content-lead__txt">
                  NRIネットコムが手がけたお客さまとのプロジェクトや書籍の一部を紹介します。
                </p>
              </div>
            </div>
            <div className="content-grid__grid-item content-grid__grid-item--end content-inner-padding">
              <div className="circle-link">
                <Link
                  to="/works/"
                  className="circle-link__txt js-hover-circle"
                  data-circle-type="stick"
                  data-circle-inner="arrow-right"
                >
                  View More
                </Link>
              </div>
            </div>
          </div>
          <div className="swiper-container works-swiper">
            <div className="works-bg">
              <div className="works-bg__inner">
                <canvas id="works-canvas" />
              </div>
            </div>
            <Suspense fallback={null}>
              <CasesFeature />
            </Suspense>
            <div className="works-swiper__pagination works-pagination">
              <div className="works-pagination__inner" />
            </div>
            <div className="swiper-button-prev works-button-prev" />
            <div className="swiper-button-next works-button-next" />
          </div>
        </section>
        <section className="content" id="blog">
          <div className="content-inner content-grid">
            <div className="content-grid__grid-item content-inner-padding">
              <h2 className="content-title">Blog</h2>
              <div className="content-lead">
                <p className="content-lead__txt">
                  さまざまな視点で、日々の気づきやナレッジを発信します。
                </p>
              </div>
            </div>
            <div className="content-grid__grid-item content-grid__grid-item--end content-inner-padding">
              <div className="circle-link">
                <Suspense fallback={null}>
                  <OuterDisclaimer top link="https://tech.nri-net.com/" />
                </Suspense>
              </div>
            </div>
          </div>
          <Suspense fallback={null}>
            <BlogFeature topLimitBlog={data.microcmsEnv.topLimitBlog} />
          </Suspense>
        </section>
        <section className="content" id="about">
          <Suspense fallback={null}>
            <AboutAnim />
          </Suspense>
        </section>
      </>
    )
  );
};

export const query = graphql`
  query Index {
    microcmsEnv {
      topLimitNews
      topLimitBlog
    }
  }
`;

export default Index;
