import Swiper, { Autoplay, Pagination, EffectFade, Navigation } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/pagination';
import 'swiper/scss/navigation';
import 'swiper/scss/effect-fade';
import imgBg from '@/assets/images/top/bg_works.webp';

const works = function () {
  Swiper.use([Autoplay, Pagination, EffectFade, Navigation]);
  let worksSwiper;
  const setSwiper = (width: number) => {
    if (width < 960) {
      worksSwiper = new Swiper(`.works-swiper`, {
        loop: true,
        allowTouchMove: true,
        slidesPerView: 1.2,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },

        pagination: {
          el: `.works-pagination__inner`,
          clickable: true,
        },
      });
    } else {
      worksSwiper = new Swiper(`.works-swiper`, {
        loop: true,
        allowTouchMove: true,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },

        effect: `fade`,
        fadeEffect: {
          crossFade: true,
        },

        pagination: {
          el: `.works-pagination__inner`,
          clickable: true,
        },

        navigation: {
          nextEl: `.works-button-next`,
          prevEl: `.works-button-prev`,
        },
      });
    }
    worksSwiper.autoplay.stop();
  };

  const canvas = <HTMLCanvasElement>document.getElementById(`works-canvas`);
  const ctx = canvas!.getContext(`2d`);
  const bgImg = new Image();
  const amplitude = 50;
  let contentHeight = 500;
  let seconds = 0;

  const update = () => {
    ctx!.globalCompositeOperation = `source-over`;
    ctx!.drawImage(bgImg, 0, 0, canvas.width, canvas.height);
    ctx!.globalCompositeOperation = `destination-in`;
    draw();
    seconds += 0.014;
    requestAnimationFrame(update);
  };

  const draw = () => {
    ctx!.beginPath();
    drawWave(2, 0, 0, -0.3, `top`);
    drawRect(amplitude, contentHeight);
    drawWave(2.2, 1, amplitude + contentHeight, -0.3, `bottom`);
    ctx!.closePath();
    ctx!.fill();
  };

  const drawRect = (topPos: number, height: number) => {
    ctx!.moveTo(0, topPos);
    ctx!.lineTo(canvas.width, topPos);
    ctx!.lineTo(canvas.width, topPos + height);
    ctx!.lineTo(0, topPos + height);
  };

  const drawWave = (
    zoom: number,
    delay: number,
    topPos: number,
    speed: number,
    position: string,
  ) => {
    // ctx!.globalAlpha = 1;
    ctx!.fillStyle = `#000`;
    if (position == `top`) {
      ctx!.moveTo(0, amplitude / 2);
    } else if (position == `bottom`) {
      ctx!.moveTo(canvas.width, topPos);
      ctx!.lineTo(0, topPos);
    }
    drawSine((seconds * Math.PI * speed) / 0.5, zoom, delay, topPos);
    if (position == `top`) {
      ctx!.lineTo(canvas.width, amplitude);
      ctx!.lineTo(0, amplitude);
    }
  };

  const drawSine = (t: number, zoom: number, delay: number, topPos: number) => {
    const yAxis: number = amplitude / 2 + topPos;
    for (let i = 0; i <= canvas.width; i += 10) {
      const x = t + i / amplitude / zoom;
      const y = Math.sin(x - delay) / 3;
      ctx!.lineTo(i, amplitude * y + yAxis);
    }
  };

  bgImg.src = imgBg;
  bgImg.onload = () => {
    ctx!.globalCompositeOperation = `source-over`;
    canvas.width = 3424;
    canvas.height = 1284;
    ctx!.drawImage(bgImg, 0, 0, 3424, 1284);
  };

  const onWindowResize = () => {
    worksSwiper.destroy(false, true);
    setSwiper(window.innerWidth);
    if (window.innerWidth >= 960 && window.innerWidth < 1700) {
      contentHeight = 500;
      return;
    }
    if (window.innerWidth < 640) {
      contentHeight = 450;
      return;
    }
    if (window.innerWidth < 960) {
      contentHeight = 400;
      return;
    }
    contentHeight = 550;
  };
  window.addEventListener(`resize`, onWindowResize);
  setSwiper(window.innerWidth);
  onWindowResize();

  const worksbg: HTMLInputElement = <HTMLInputElement>(
    document.querySelector(`.works-swiper`)
  );
  const worksbgObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          update();
          entry.target.classList.add(`is-slide`);
          setTimeout(() => {
            entry.target.classList.add(`is-show`);
            worksSwiper.autoplay.start();
          }, 1000);
          worksbgObserver.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: `-20% 0px`,
      threshold: 0,
    },
  );

  worksbgObserver.observe(worksbg);
};

export default works;
