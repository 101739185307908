// ■コンポーネント説明
// トップページへのサービス表示用コンポーネント

import React from 'react';
import { Link } from 'gatsby';

import videoWebSolution from '@/assets/video/top/bg_top_service_01.mp4';
import videoWebCreative from '@/assets/video/top/bg_top_service_02.mp4';
import videoDigitalMarketing from '@/assets/video/top/bg_top_service_03.mp4';
import videoCloudSolution from '@/assets/video/top/bg_top_service_04.mp4';

const ServiceFeature: React.FC = () => (
  <>
    <ul className="service-content-list">
      <li className="service-content-list__item --service01" key="web-solution">
        <Link
          to="/service/web-solution/"
          className="service-item js-hover-circle"
          data-circle-type="stick-target-noReverse"
          data-circle-inner="arrow-right"
        >
          <div className="service-item-img">
            <div className="service-item-img__bg">
              <video className="top-service-video" playsInline loop muted>
                <source src={videoWebSolution} type="video/mp4" />
                <p>ご使用のブラウザでは動画再生に対応していません</p>
              </video>
            </div>
            <div className="service-item-img__bg-cover" />
            <div className="service-item-img__wave-top" />
            <div className="service-item-img__wave-bottom" />
          </div>
          <div className="service-item__txt service-item-txt">
            <div className="service-item-txt__title">Web Solution</div>
            <div className="service-item-txt__lead">
              さまざまな業種のお客さまに対してWebシステムを構築・運用する事業です。パブリッククラウド、モバイルデバイスなどの先端技術を活用し、課題解決をご支援します。
            </div>
            <div className="circle-link">
              <span className="circle-link__txt js-stick-target">
                View More
              </span>
            </div>
          </div>
        </Link>
      </li>
      <li className="service-content-list__item --service02" key="web-creative">
        <Link
          to="/service/web-creative/"
          className="service-item js-hover-circle"
          data-circle-type="stick-target-noReverse"
          data-circle-inner="arrow-right"
        >
          <div className="service-item-img">
            <div className="service-item-img__bg">
              <video className="top-service-video" playsInline loop muted>
                <source src={videoWebCreative} type="video/mp4" />
                <p>ご使用のブラウザでは動画再生に対応していません</p>
              </video>
            </div>
            <div className="service-item-img__bg-cover" />
            <div className="service-item-img__wave-top" />
            <div className="service-item-img__wave-bottom" />
          </div>
          <div className="service-item__txt service-item-txt">
            <div className="service-item-txt__title">Web Creative</div>
            <div className="service-item-txt__lead">
              顧客のビジネスや経営課題を、Webを活用して解決に導く事業です。現状の分析から課題解決に必要な戦略立案、Webサイト構築までをトータルで行っています。
            </div>
            <div className="circle-link">
              <span className="circle-link__txt js-stick-target">
                View More
              </span>
            </div>
          </div>
        </Link>
      </li>
      <li className="service-content-list__item --service03" key="digital-marketing">
        <Link
          to="/service/digital-marketing/"
          className="service-item js-hover-circle"
          data-circle-type="stick-target-noReverse"
          data-circle-inner="arrow-right"
        >
          <div className="service-item-img">
            <div className="service-item-img__bg">
              <video className="top-service-video" playsInline loop muted>
                <source src={videoDigitalMarketing} type="video/mp4" />
                <p>ご使用のブラウザでは動画再生に対応していません</p>
              </video>
            </div>
            <div className="service-item-img__bg-cover" />
            <div className="service-item-img__wave-top" />
            <div className="service-item-img__wave-bottom" />
          </div>
          <div className="service-item__txt service-item-txt">
            <div className="service-item-txt__title">Digital Marketing</div>
            <div className="service-item-txt__lead">
              多様なデバイスを通じて収集したデータの解析を行う事業です。高度な分析からデジタル広告まで、さまざまな側面からお客さまのマーケティング活動をご支援しています。
            </div>
            <div className="circle-link">
              <span className="circle-link__txt js-stick-target">
                View More
              </span>
            </div>
          </div>
        </Link>
      </li>
      <li className="service-content-list__item --service04" key="cloud-solution">
        <Link
          to="/service/cloud-solution/"
          className="service-item js-hover-circle"
          data-circle-type="stick-target-noReverse"
          data-circle-inner="arrow-right"
        >
          <div className="service-item-img">
            <div className="service-item-img__bg">
              <video className="top-service-video" playsInline loop muted>
                <source src={videoCloudSolution} type="video/mp4" />
                <p>ご使用のブラウザでは動画再生に対応していません</p>
              </video>
            </div>
            <div className="service-item-img__bg-cover" />
            <div className="service-item-img__wave-top" />
            <div className="service-item-img__wave-bottom" />
          </div>
          <div className="service-item__txt service-item-txt">
            <div className="service-item-txt__title">Cloud Solution</div>
            <div className="service-item-txt__lead">
              お客さまの目的や課題に対して最適なクラウドアーキテクチャを提供する事業です。経験豊富なスペシャリストが、システム構築や改善のアドバイスを行います。
            </div>
            <div className="circle-link">
              <span className="circle-link__txt js-stick-target">
                View More
              </span>
            </div>
          </div>
        </Link>
      </li>
    </ul>
  </>
);

export default ServiceFeature;
