const service = function () {
  let isPC = true;
  const ua = navigator.userAgent.toLowerCase();
  if (
    /android|ipod|ipad|iphone|macintosh/.test(ua) &&
    `ontouchend` in document
  ) {
    isPC = false;
  }

  const contentList = [...document.querySelectorAll(`.service-item`)];
  const videoList = [...document.querySelectorAll(`.top-service-video`)];
  const leadObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add(`--smoothIn`);
          entry.target
            .querySelector(`.service-item-img__bg-cover`)
            .classList.add(`--slideIn`);
          leadObserver.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: `-30% 0px`,
      threshold: 0,
    },
  );

  const addVideoEvent = (
    contentListItem: Element,
    videoElement: HTMLVideoElement,
  ) => {
    contentListItem.addEventListener(`mouseenter`, () => {
      if(innerWidth < 980 || !isPC) return;
      videoElement.play();
    });
    contentListItem.addEventListener(`mouseleave`, () => {
      if(innerWidth < 980 || !isPC) return;
      videoElement.pause();
    });
  };

  contentList.forEach((contentListItem) => {
    leadObserver.observe(contentListItem);
    const videoElement: HTMLVideoElement =
      contentListItem.querySelector(`.top-service-video`);
    addVideoEvent(contentListItem, videoElement);
  });

  const onWindowResize = () => {
    if(innerWidth < 980 || !isPC) {
      videoList.forEach((videoListItem: HTMLVideoElement) => {
        videoListItem.play();
      });
    } else {
      videoList.forEach((videoListItem: HTMLVideoElement) => {
        videoListItem.pause();
      });
    }
  };
  window.addEventListener(`resize`, onWindowResize);
  onWindowResize();
};

export default service;
