import * as THREE from 'three';
import THREE_Meshline from 'three.meshline';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import imgMvStroke from '@/assets/images/top/img_mv_stroke.png';

const mv = function () {
  type waveType = {
    // 束
    x: number[];
    y: number[];
    xSlope: number[];
    ySlope: number[];
  };

  type aloneLine = {
    // 単線
    x: number[];
    y: number[];
    z: number[];
    color: string; // 線の色
    slopeX: number; // 線の傾き
    radius: number; // 線の半径
    time: number; // 進行時間
    transformingTime: number; // 変形中の時間
    transformingSlopeXDiff: number; // 傾き変形量
    transformingRadiusDiff: number; // 半径変形量
    aloneCurrentProgress: number;
    height: number; // 線の長さ
    translateX: number; // X軸の位置
    pointsNumber: number; // 頂点数
    startPosY: number; // Y軸の位置
    mesh: any; // new THREE.Mesh()
    meshLine: any; // new THREE_Meshline.MeshLine()
    baseUnit: number; // 円周の基礎単位
    lineWidth: number; // 太さ
    transformSlopeX: { normal: number; curl: number; tight: number }; // 次の形態の変化量
    transformRadius: { normal: number; curl: number; tight: number }; // 次の形態の変化量
  };

  const scene = new THREE.Scene();
  const MeshLineArray: THREE_Meshline.MeshLine = [];
  const pointsInitX: number[] = [];
  const pointsInitY: number[] = [];
  const slopeY: number[] = [];
  const slopeX: number[] = [];
  const lineNumber = 15;
  const transformDuration = 170;
  const transformStart = 0;
  const stayDuration = 1;
  const transformPhase: number[] = [
    stayDuration,
    stayDuration + transformDuration,
    stayDuration * 2 + transformDuration,
    stayDuration * 2 + transformDuration * 2,
    stayDuration * 3 + transformDuration * 2,
    stayDuration * 3 + transformDuration * 3,
  ];
  const currentWavePoint: waveType = {
    x: [],
    y: [],
    xSlope: [],
    ySlope: [],
  };
  // デフォルト
  const normal: waveType = {
    x: [58, 43, 43, 82, 72, 67],
    y: [28, 41, 49, 64, 85, 114],
    xSlope: [0.4, 0.6, 0.7, -0.2, -0.3, 2],
    ySlope: [0.1, -0.4, -0.6, -0.7, -1.0, -1.3],
  };
  // ゆるむ
  const curl: waveType = {
    x: [65, 78, 77, 52, 90, 62],
    y: [22, 33, 38, 59, 73, 136],
    xSlope: [-0.6, -0.7, 0.4, 0.9, 0.1, 0.9],
    ySlope: [1.2, 1.1, 1.0, 0.9, 0.6, -2.0],
  };
  // はりつめる
  const tight: waveType = {
    x: [52, 65, 67, 69, 77, 122],
    y: [1, 31, 35, 54, 73, 150],
    xSlope: [0.3, 0.3, 0.2, 0.5, 0.1, 0.2],
    ySlope: [1.2, 0.8, 1.2, 0.8, 0.6, -2.0],
  };
  // 初期状態
  const wavePoint: waveType = {
    x: [...normal.x],
    y: [...normal.y],
    xSlope: [...normal.xSlope],
    ySlope: [...normal.ySlope],
  };
  const currentWaveZ: number[] = [];
  for (let i = 0; i < lineNumber; i++) {
    currentWaveZ.push(9);
  }
  const targetWaveZ: number[] = [];
  for (let i = 0; i < lineNumber; i++) {
    targetWaveZ.push(i);
  }
  const wavePointZ: number[] = [...currentWaveZ];
  const waveMesh = [];
  let renderActive = true;
  let waveCurrentProgress = 0;
  let startShowAnimation = true;
  const motionZTime = 0;
  let idle = 0;
  let waveTime = 0;
  let time = 0;
  let points: THREE.Vector3[] = [];
  let currentWaveName = `normal`;
  const waveNoise: number[] = [];
  const aloneNoise: number[] = [];

  // lineAlone
  const aloneLineName = [`blue`, `green`, `red`];
  const aloneLineRed: aloneLine = {
    x: [],
    y: [],
    z: [],
    color: `rgba(255,106,95,1)`,
    slopeX: 29,
    radius: 5,
    time: 0,
    transformingTime: 0,
    transformingSlopeXDiff: 0,
    transformingRadiusDiff: 0,
    aloneCurrentProgress: 0,
    height: 70,
    translateX: 43,
    pointsNumber: 20,
    startPosY: 0,
    mesh: undefined,
    meshLine: undefined,
    baseUnit: 0,
    lineWidth: 0.2,
    transformSlopeX: {
      normal: -5,
      curl: 10,
      tight: -5,
    },
    transformRadius: {
      normal: 15,
      curl: 5,
      tight: -20,
    },
  };
  const aloneLineGreen: aloneLine = {
    x: [],
    y: [],
    z: [],
    color: `rgba(101,170,119,1)`,
    slopeX: 25,
    radius: 12,
    time: 0,
    transformingTime: 0,
    transformingSlopeXDiff: 0,
    transformingRadiusDiff: 0,
    aloneCurrentProgress: 0,
    height: 50,
    translateX: 40,
    pointsNumber: 20,
    startPosY: 0,
    mesh: undefined,
    meshLine: undefined,
    baseUnit: 0,
    lineWidth: 0.35,
    transformSlopeX: {
      normal: -5,
      curl: 10,
      tight: -5,
    },
    transformRadius: {
      normal: 15,
      curl: 5,
      tight: -20,
    },
  };
  const aloneLineBlue: aloneLine = {
    x: [],
    y: [],
    z: [],
    color: `rgba(68,115,150,1)`,
    slopeX: 35,
    radius: 8,
    time: 0,
    transformingTime: 0,
    transformingSlopeXDiff: 0,
    transformingRadiusDiff: 0,
    aloneCurrentProgress: 0,
    height: 87,
    translateX: 45,
    pointsNumber: 20,
    startPosY: 0,
    mesh: undefined,
    meshLine: undefined,
    baseUnit: 0,
    lineWidth: 0.25,
    transformSlopeX: {
      normal: -5,
      curl: 10,
      tight: -5,
    },
    transformRadius: {
      normal: 15,
      curl: 5,
      tight: -20,
    },
  };
  const alonePoints = {
    blue: [],
    green: [],
    red: [],
  };
  const motionZDuration = 50;
  const alonePlusSpeed = 0.5;

  const mv = document.querySelector(`.mv`);
  const mvLead = mv.querySelector(`.mv-lead`);
  const header = document.querySelector(`.header-wrap`);

  // 視野角, アスペクト比, near, far
  const camera = new THREE.PerspectiveCamera(
    70,
    window.innerWidth / window.innerHeight,
    0.1,
    1000,
  );

  // レンダラ設定
  const container = document.querySelector(`.mv__container`);
  const renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
  });

  // 描画内容
  const resolution = new THREE.Vector2(window.innerWidth, window.innerHeight);
  let strokeTexture;
  const loader = new THREE.TextureLoader();
  loader.load(imgMvStroke, (texture) => {
    strokeTexture = texture;
    strokeTexture.wrapS = strokeTexture.wrapT = THREE.RepeatWrapping;
    main();
  });

  async function main() {
    await init();
    render();
  }

  async function init() {
    return new Promise((resolve) => {
      initLinePoints();

      for (let i = 0; i < lineNumber; i++) {
        waveNoise.push((Math.random() - 0.5) * 1);
      }

      const targetBlue = aloneLineTraget(`blue`);
      for (let i = 0; i < targetBlue.pointsNumber; i++) {
        aloneNoise.push((Math.random() - 0.5) * 0.1);
      }

      // lineWave
      for (let lineIndex = 0; lineIndex < lineNumber; lineIndex++) {
        updateLinePoints(lineIndex, 0);

        const linePoints = new THREE.Geometry().setFromPoints(
          new THREE.CatmullRomCurve3(points).getPoints(200),
        );
        MeshLineArray[lineIndex] = new THREE_Meshline.MeshLine();
        MeshLineArray[lineIndex].setGeometry(linePoints);
        const { geometry } = MeshLineArray[lineIndex];
        const material = new THREE_Meshline.MeshLineMaterial({
          useMap: true,
          map: strokeTexture,
          opacity: 1,
          depthTest: false,
          blending: THREE.NormalBlending,
          transparent: true,
          repeat: new THREE.Vector2(1, 1),
          lineWidth: 0.2,
          color: new THREE.Color(`rgba(255,106,95,1)`),
          resolution,
        });
        waveMesh[lineIndex] = new THREE.Mesh(geometry, material);
        scene.add(waveMesh[lineIndex]);
      }

      // lineAlone
      for (const name of aloneLineName) {
        const target = aloneLineTraget(name);

        for (let i = 0; i < target.pointsNumber; i++) {
          target.baseUnit = (i * target.height) / target.pointsNumber;
          target.x.push(
            target.radius *
            Math.cos((target.baseUnit / target.height) * 2 * Math.PI) +
            target.translateX,
          );
          target.z.push(
            target.radius *
            Math.sin((target.baseUnit / target.height) * 2 * Math.PI),
          );
          target.y.push(target.baseUnit + target.startPosY);
        }
        for (let i = 0; i < target.x.length; i++) {
          setAlone3DPoints(name, i, target.x[i], target.y[i], target.z[i]);
        }
        const aloneLinePoints = new THREE.Geometry().setFromPoints(
          new THREE.CatmullRomCurve3(alonePoints[name]).getPoints(100),
        );
        target.meshLine = new THREE_Meshline.MeshLine();
        target.meshLine.setGeometry(aloneLinePoints);
        const aloneGeometry = target.meshLine.geometry;
        const aloneMaterial = new THREE_Meshline.MeshLineMaterial({
          useMap: true,
          map: strokeTexture,
          opacity: 1,
          depthTest: false,
          blending: THREE.NormalBlending,
          transparent: true,
          repeat: new THREE.Vector2(1, 1),
          lineWidth: target.lineWidth,
          color: new THREE.Color(`${target.color}`),
          resolution,
        });
        target.mesh = new THREE.Mesh(aloneGeometry, aloneMaterial);
        scene.add(target.mesh);
      }

      renderer.render(scene, camera);

      // GSAP
      gsap.registerPlugin(ScrollTrigger);
      gsap.set(camera.position, { x: 70, y: 40, z: 60 });
      gsap.set(camera.rotation, { x: 0.3, y: 0.3, z: 0 });
      const tl = new (gsap.timeline as any)({
        scrollTrigger: {
          trigger: `.mv`,
          scrub: true,
          start: `top top`,
          end: `bottom bottom`,
        },
        defaults: { duration: 1, ease: `power2.inOut` },
      });
      tl.to(
        camera.position,
        { x: 50, y: 100, z: -150, ease: `power1.easeInOut` },
        0,
      );
      tl.to(camera.rotation, { x: -1.2, y: -1.0, ease: `power1.easeIn` }, 0);

      // スクロールイベント
      const mvLeadDisappear = (scrollY: number) => {
        if (scrollY < 450) {
          mvLead.classList.remove(`is-hide`);
        } else {
          mvLead.classList.add(`is-hide`);
        }
      };
      const renderSwitch = (scrollY: number) => {
        const mvHeight = mv.getBoundingClientRect().height;
        if (scrollY < mvHeight / 2) {
          renderActive = true;
          mv.classList.remove(`is-hide`);
        } else {
          renderActive = false;
          header.classList.add(`is-show`);
          mv.classList.add(`is-hide`);
        }
      };
      const onScroll = () => {
        const { scrollY } = window;
        mvLeadDisappear(scrollY);
        renderSwitch(scrollY);
      };
      window.addEventListener(`scroll`, onScroll);
      onScroll();

      // リサイズ処理
      const onResize = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(width, height);
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
      };
      onResize();
      window.addEventListener(`resize`, onResize);

      container!.appendChild(renderer.domElement);

      return resolve(true);
    });
  }

  function render() {
    idle++;

    if (idle > 3 && renderActive) {
      time++;

      // lineAlone
      for (const name of aloneLineName) {
        const target = aloneLineTraget(name);
        target.transformingTime++;
        const aloneLinePoints = new THREE.Geometry().setFromPoints(
          new THREE.CatmullRomCurve3(alonePoints[name]).getPoints(100),
        );
        target.meshLine.setGeometry(aloneLinePoints);

        let speedScale: number;
        const progress: number = easeInOutSine(
          target.transformingTime / transformDuration,
        );
        const progressDiff = progress - target.aloneCurrentProgress;
        if (
          target.transformingTime > transformDuration / 4 &&
          target.transformingTime < (transformDuration * 2) / 4
        ) {
          // 加速中
          speedScale = easeInOutSine(
            ((target.transformingTime - transformDuration / 4) /
              transformDuration) *
            4,
          );
        } else if (
          target.transformingTime > (transformDuration * 2) / 4 &&
          target.transformingTime < (transformDuration * 3) / 4
        ) {
          // 減速中
          speedScale = easeInOutSine(
            (((transformDuration * 3) / 4 - target.transformingTime) * 4) /
            transformDuration,
          );
        } else {
          // 等速速
          speedScale = 0;
        }
        target.time += speedScale * 0.8 + 0.4;
        updateAlone(name, target.time, progressDiff);
        target.aloneCurrentProgress = progress;
      }

      // wave
      waveTime = time % transformPhase[5];
      for (let i = 0; i < lineNumber; i++) {
        updateLinePoints(i, time);
        const linePoints = new THREE.Geometry().setFromPoints(
          new THREE.CatmullRomCurve3(points).getPoints(170),
        );
        MeshLineArray[i].setGeometry(linePoints);
      }

      const changeSpeed = false;
      if (waveTime > transformPhase[0] && waveTime <= transformPhase[1]) {
        const waveTimeLine = (waveTime - transformPhase[0]) / transformDuration;
        waveTransform(currentWaveName, waveTimeLine);
      } else if (
        waveTime > transformPhase[2] &&
        waveTime <= transformPhase[3]
      ) {
        const waveTimeLine = (waveTime - transformPhase[2]) / transformDuration;
        waveTransform(currentWaveName, waveTimeLine);
      } else if (
        waveTime > transformPhase[4] &&
        waveTime <= transformPhase[5]
      ) {
        const waveTimeLine = (waveTime - transformPhase[4]) / transformDuration;
        waveTransform(currentWaveName, waveTimeLine);
      } else if (
        waveTime == transformPhase[0] ||
        waveTime == transformPhase[2] ||
        waveTime == transformPhase[4]
      ) {
        for (let i = 0; i < 6; i++) {
          currentWavePoint.x[i] = wavePoint.x[i];
          currentWavePoint.y[i] = wavePoint.y[i];
          currentWavePoint.xSlope[i] = wavePoint.xSlope[i];
          currentWavePoint.ySlope[i] = wavePoint.ySlope[i];
          switch (waveTime) {
            case transformPhase[0]:
              currentWaveName = `curl`;
              break;
            case transformPhase[2]:
              currentWaveName = `tight`;
              break;
            case transformPhase[4]:
              currentWaveName = `normal`;
              break;
          }
          for (const name of aloneLineName) {
            const target = aloneLineTraget(name);
            target.transformingTime = 0;
            target.aloneCurrentProgress = 0;
            target.transformingSlopeXDiff =
              target.transformSlopeX[currentWaveName];
            target.transformingRadiusDiff =
              target.transformRadius[currentWaveName];
          }
        }
        waveCurrentProgress = 0;
      }
      renderer.render(scene, camera);
      if (time > 2 && startShowAnimation) {
        const leadTitle: HTMLInputElement = <HTMLInputElement>(
          document.querySelector(`.mv-lead__title`)
        );
        const leadTxt: HTMLInputElement = <HTMLInputElement>(
          document.querySelector(`.mv-lead__txt`)
        );
        header.classList.add(`is-show`);
        leadTitle.classList.add(`--smoothInSkew`);
        leadTxt.classList.add(`--smoothIn`);
        container.classList.add(`--opacityShow`);
        startShowAnimation = false;
      }

      idle = 0;
    }
    window.requestAnimationFrame(render);
  }

  // lineAlone target
  function aloneLineTraget(name: string) {
    switch (name) {
      case `red`:
        return aloneLineRed;
      case `green`:
        return aloneLineGreen;
      case `blue`:
        return aloneLineBlue;
    }
    return aloneLineRed;
  }

  // lineAlone 3D位置設置
  function setAlone3DPoints(
    aloneName: string,
    index: number,
    x: number,
    y: number,
    z: number,
  ) {
    alonePoints[aloneName][index] = new THREE.Vector3(x, y, z);
  }

  // lineAlone 3D位置更新
  function updateAlone(
    aloneName: string,
    aloneTime: number,
    progressDiff: number,
  ) {
    const target = aloneLineTraget(aloneName);
    target.slopeX += target.transformingSlopeXDiff * progressDiff;
    target.radius += target.transformingRadiusDiff * progressDiff;
    alonePoints[aloneName] = [];
    target.baseUnit = 0;
    for (let i = 0; i < target.pointsNumber; i++) {
      target.baseUnit = (i * target.height) / target.pointsNumber;
      let multipleNum = 1;
      let plusNum = 1.6;
      switch (aloneName) {
        case `red`:
          multipleNum = 2;
          break;
        case `blue`:
          multipleNum = 2;
          plusNum = 1;
          break;
        case `green`:
          plusNum = 0.6;
          break;
      }
      target.x[i] =
        target.radius *
        Math.cos(
          Math.PI * plusNum +
          ((target.baseUnit + aloneTime + aloneNoise[i]) / target.height) *
          2 *
          Math.PI *
          multipleNum,
        ) +
        target.translateX +
        (target.slopeX * i) / target.pointsNumber;
      target.z[i] =
        target.radius *
        Math.sin(
          Math.PI * plusNum +
          ((target.baseUnit + aloneTime + aloneNoise[i]) / target.height) *
          2 *
          Math.PI *
          multipleNum,
        );
      target.y[i] = target.baseUnit + target.startPosY;
      setAlone3DPoints(aloneName, i, target.x[i], target.y[i], target.z[i]);
    }
  }

  // wave Points基本位置設置
  function setInitPoints(
    index: number,
    x: number,
    y: number,
    slopeXnum: number,
    slopeYnum: number,
  ) {
    pointsInitX[index] = x;
    pointsInitY[index] = y;
    slopeX[index] = slopeXnum;
    slopeY[index] = slopeYnum;
  }

  // wave 2D位置設置
  function initLinePoints() {
    for (let i = 0; i < 6; i++) {
      setInitPoints(
        i,
        wavePoint.x[i],
        wavePoint.y[i],
        wavePoint.xSlope[i],
        wavePoint.ySlope[i],
      );
    }
  }

  // wave 3D位置設置
  function set3DPoints(index: number, x: number, y: number, z: number) {
    points[index] = new THREE.Vector3(x, y, z);
  }
  // wave 3D位置更新
  function updateLinePoints(lineIndex: number, updateTime: number) {
    points = [];
    // 0
    set3DPoints(0, -100, -20, -lineIndex);
    // 1
    set3DPoints(1, -20 + lineIndex * 4, 0, -lineIndex);
    // 2 - 7
    for (let i = 2; i < 8; i++) {
      set3DPoints(
        i,
        pointsInitX[i - 2] +
        slopeX[i - 2] * lineIndex +
        Math.sin((updateTime - lineIndex - i * 10) / 30),
        pointsInitY[i - 2] + slopeY[i - 2] * lineIndex,
        lineIndex - lineNumber / 2 + waveNoise[lineIndex],
      );
    }
    // 8
    set3DPoints(8, 250, 200, lineIndex - lineNumber / 2);
  }

  // wave変形
  function waveTransform(waveName: string, progress: number) {
    progress = easeInOutSine(progress);
    const progressDiff = progress - waveCurrentProgress;

    switch (waveName) {
      case `normal`:
        for (let i = 0; i < 6; i++) {
          wavePoint.x[i] +=
            (normal.x[i] - currentWavePoint.x[i]) * progressDiff;
          wavePoint.y[i] +=
            (normal.y[i] - currentWavePoint.y[i]) * progressDiff;
          wavePoint.xSlope[i] +=
            (normal.xSlope[i] - currentWavePoint.xSlope[i]) * progressDiff;
          wavePoint.ySlope[i] +=
            (normal.ySlope[i] - currentWavePoint.ySlope[i]) * progressDiff;
        }
        break;

      case `curl`:
        for (let i = 0; i < 6; i++) {
          wavePoint.x[i] += (curl.x[i] - currentWavePoint.x[i]) * progressDiff;
          wavePoint.y[i] += (curl.y[i] - currentWavePoint.y[i]) * progressDiff;
          wavePoint.xSlope[i] +=
            (curl.xSlope[i] - currentWavePoint.xSlope[i]) * progressDiff;
          wavePoint.ySlope[i] +=
            (curl.ySlope[i] - currentWavePoint.ySlope[i]) * progressDiff;
        }
        break;

      case `tight`:
        for (let i = 0; i < 6; i++) {
          wavePoint.x[i] += (tight.x[i] - currentWavePoint.x[i]) * progressDiff;
          wavePoint.y[i] += (tight.y[i] - currentWavePoint.y[i]) * progressDiff;
          wavePoint.xSlope[i] +=
            (tight.xSlope[i] - currentWavePoint.xSlope[i]) * progressDiff;
          wavePoint.ySlope[i] +=
            (tight.ySlope[i] - currentWavePoint.ySlope[i]) * progressDiff;
        }
        break;
    }

    waveCurrentProgress = progress;
    initLinePoints();
  }

  // 参照：https://easings.net/ja
  // easing x:0~1
  function easeInOutSine(x: number): number {
    return -(Math.cos(Math.PI * x) - 1) / 2;
  }
};

export default mv;
